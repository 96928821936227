html {
  box-sizing: border-box;
}
:root {
  --main-background-color: #fff;
  --highlight-color: blue;
  --main-color:#333;
  --booking-box-color:#ccc;
  --box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  --background-quotation:#0072bb;
  --background-dashboard:#FFFFFF;
  --background-yourjobs:#ED2939;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  /* background-color: #d5c0bf; */
  background-color:var(--main-background-color);
  color:var(--main-color); /* Text color on the background */
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

h1 {
  font-size: 2.5rem; 
  margin-bottom: 20px;
}

p {
  font-size: 1em; /* Adjust the font size for paragraphs using relative units */

}

button {
  font-size: 1rem; /* Adjust the font size for buttons using relative units */
  padding: 10px 20px; /* Adjust padding for buttons */
  background-color: var(--main-color); 
  color: var(--main-background-color); 
  border: none;
  cursor: pointer;
  
}
.app-container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Media query for smaller screens */
@media only screen and (max-width: 900px) {
  h1 {
    font-size: 1.5rem; /* Adjust h1 font size for smaller screens */
  }
  h2{
    font-size: 1.3em;
  }

  p {
    font-size: 0.8rem; /* Adjust paragraph font size for smaller screens */
  }

  button {
    font-size: 0.9rem; /* Adjust button font size for smaller screens */
  }
}

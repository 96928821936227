/* Global for booking  */

.booking-container{
  /* background-color: white; */
  /* background-color:#d5c0bf; */
  background-color: var(--main-background-color);
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.booking-container h1{
  font-size: 1.4em;
  text-align: center;
  color: var(--highlight-color);
}
.booking-container h2{
  font-size: 1.2em;
  color: var(--highlight-color);
  /* margin-bottom: 1vh; */
text-align: center;
  
}


.booking-container p{
  font-size: 1em;
  /* margin-bottom: 0.8vh; */
  /* text-align: left; */
  /* padding: auto 1vw; */
}
.wheremoving-form  input,select{
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
}



.movingdate input{
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 240px;
  height: 32px;
  padding: 3px 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  
}
/* this is end of Global */
/* start Popup Booking Van Size guide*/
.popup {
  padding-top: 20px;
  margin: 0 auto;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(236, 150, 150);
  opacity: 0.9;
  z-index: 1;
}

.popupactive {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  gap: 2em;
  overflow-y: auto;
  background-color: #ccc;
  top: 2em;
}

.popupactive h1 {
  text-align: center;
  max-width: 90%;
}

.popup-content {
  display: flex;
  flex-direction: row;
  
  gap: 3em;
  color: #111;
  background-color: aliceblue;
margin:5vw;
}

.popuptext p {
  padding: 2em 0;
}

.popuptext h2 {
  text-align: center;
  width: auto;
}

.popup-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-image img {
  max-width: 12em;
  max-height: 12em;
}

.closepopup {
  position: absolute;
  top: 2em;
  right: 5em;
  cursor: pointer;
  transition: transform 0.3s;
}

.closepopup:hover {
  transform: rotate(180deg);
}
/* end of popup vansizeguide */


/* start General mix all  */
.booking-header,
/* .choosevansize, */
.booking-vansize,
.booking-helper,
.loading-header,
.loading-image,
.wheremoving-header,
.wheremoving-form,
.canitravel,
.mileandhour,
/* .choosetime, */
.movingdate
/* .aboutyou  */
/* .aboutyou-info */
 {
margin:5vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1vw;
  background-color:var(--booking-box-color);
  width: 80vw;
  padding:1em;
  border-radius: 10%;
}


/* end of general mix all */

/* start booking-header */
.booking-header button{
  align-self: flex-end;
}

/* end booking-header */

/* startboth radio vansize and helper */
.booking-vansize,
.loading-image{
  justify-content: center;
  gap:5vw;
}
/* .booking-vansize{
  gap:5vw;
} */
/* end radio vansize and helper */

.radio-container {
  display: flex;
  position: relative;
  flex-direction: column; /* Display items in a column */
  justify-content: center;
  text-align: center;
  gap:10vw;
  border: 5px solid goldenrod;
  width: 15vw;
  background-size: cover;
  background-position: center; 
  border-radius: 10%;
  
}
.radio-container p{
  text-align: center;
}

.radio-image-container img{
width: 100%;
}
 .active-radio{
  padding:2vw;
  background-color:#d5c0bf;
}
.readiodisplay{
  font-size: 0.2em;
  font-weight: bold;
  
}





/* Where Moving Form */


.wheremoving-form {
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  /* margin: 5vh;  */
  justify-content: center;
  /* background-color:pink; */
  background-color:var(--booking-box-color)
   /* padding:3em; */
  /* min-width: 90vw; */
  }
  /* .wheremoving-form-title */
  
 .wheremoving-form-title{
  display: flex;
  justify-content: center;
 }
 .wheremoving-form-warning{
  display: flex;
  justify-content: center;
  color:red;
  font-weight: bold;
 }
.collection,
.destination,
.viastopinput
{
  display: flex;
  flex-direction: row;
  gap:2vw;
  justify-content: center;
  min-width: 80%;
  background-color: #ccc;
  border:4px solid #111;
  padding-bottom: 2vh;
  /* margin:5vw; */
  
}
.collection div,
.destination div,
.viastopinput div
{
  display: grid;
  grid-template-rows: min-content 1fr;
  gap:1vh;
}
.collection h2{
  padding:0;
  margin:0;
}
.collection div select, 
.collection div label,
.collection div input,
.destination div select, 
.destination div label,
.destination div input,
.viastopinput div select, 
.viastopinput div label,
.viastopinput div input
{
width:80%;
height: auto;
text-align: center;
margin:0 auto;
padding:1em;


}
.collection div select,
.collection div input,
.destination div select,
.destination div input,
.viastopinput div select,
.viastopinput div input
{
  border: 3px solid black;
  border-radius: 5%;
}

/* .button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5vw;
  margin-top: 4vh;
  padding:0;
} */
.remove-via{
margin: 0 auto;
  padding: 1em;
  background-color:rgb(141, 108, 108);
  cursor: pointer;
  width: 40%;
}
.viacontainer {
  display: flex;
  flex-direction: column;
}
/* where moving form end */
/* start estimate how many hour */
.estimatediv{
  text-align: center;
}
.estimatediv ul{
  display: grid;
  grid-template-columns:repeat(3,auto);
  gap: 1em;
  background-color:lightblue;
  padding: 1em;
  max-width: 90%;
  border-radius: 10%;
  margin:1em;

}
.estimatediv li{
  list-style: none;
}
/* end estimate how many hour */

/* choose time start */

.choosetime {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:2vh;
  background-color: lightblue;
padding-bottom: 1em;
  max-width: 90%;
}
.choosetime h3{
text-align: center;}
.choosetime select{
  width: 50%;
  /* padding:100%; */
}





/* choose time end */

/* start moving date */

.movingdate-description {
  /* display: flex;
  flex-direction: column;
  align-items: left;
  position: relative; */
  margin-left: 5vw;
}
.movidedate-header .note{
  font-weight: bold;
  padding:0 10px;
}
.movingdate-description{
  text-align: center;
}

.datepicker{
  width: 50%;
  /* padding-bottom: 2vh; */
}
.movingdate-description  textarea {
 width: 90%;
  margin:2vh 0;
 
}
/* aboutyou :start */
.aboutyou {
  margin-top: 20px;

  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: var(--booking-box-color);
  width: 80vw;
  /* padding:1em; */
  border-radius: 10%;
}

.aboutyou-header {
  margin-bottom: 15px;
  margin:20px;
}

.aboutyou-info {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
}

.aboutyousubdiv {
  /* flex: 1; */
  margin-right: 15px;
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}




/* about you end */
/* end moving date */
/* aboutyou start*/
/* .aboutyousubdiv{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
} */
/* about you end */
/* start booking error */
.error-input-booking{
color:red;
font-weight: bold;
margin:0 auto;
text-align: center;
}

.booking-container button{
  margin:2vh 0;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;

}
.booking-container button:hover{
  background-color: var(--highlight-color);
  color:#111;
  font-weight: bold;
  transform: scale(1.1);

}
/* end booking error */
@media (max-width:1000px){
  .floatingbook{
    font-size: 0.7em;
  }
  .floatingcontent{
    margin-left: 0;
  }
.popup-content {
  display: flex;
  flex-direction: column;

}
.estimatediv ul{
  /* display: grid;
  grid-template-columns:repeat(3,auto); */
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color:azure;
  padding: 10px;
  max-width: 90%;
  /* justify-content: center; */
  
}

.radio-container {
  flex: 0 0 35%; /*This is to get 2 vs 2 */
  max-width: 35%;
}

/* formdata */
.collection,.destination,.viastopinput{
  flex-direction: column;
 
}
}
/* Styles for smaller screens */

@media (max-width:600px) {
  .readiodisplay{
   display: none;
  }
 
  .floatingpickup,
  .floatingviastop,
  .floatingdropof,
  .floatingworker,
  .floatingvansize,
  .floatinghourNeed
  {
    border: 1px solid rgb(58, 38, 211);

  }
  .aboutyou-info{
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }
}
.login-container,.register-container{
  width: 50vw;
  height: auto;
  margin:20px auto;
  text-align: center;
  /* border: 1px solid gold; */
  padding:20px;
}
.login-container input, .login-button,.register-button{
  margin: 10px auto;
}
.account-button{
  display: flex;
}
.register-form-error{
  font-size: 1em;
  color: red;
}
/* admindashboard */
.admin-dashboard{
width: 80vw;
margin:2vh auto;
border: 1px solid black;
}
.admin-dashboard-topbar{
  display: flex;
justify-content: flex-end;
gap:20px;
}
/* main page css */
.card-container-admin-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin:20px;
}

.card-main {
  background-color:var(--main-background-color);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  color: var(--main-color);
  color:#222;

}
.card-main:hover{
  background-color: antiquewhite;
}
.card-main-quotation{
  background-color:var(--background-quotation);
}
.card-main-dashboard{
  background-color:var(--background-dashboard);
}
.card-main-yourjobs{
  background-color:var(--background-yourjobs);
  
}
/* main page css finish */
/* quotation */
.card-container-quotation {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin:10px;
}
.card-quotation {
  background-color: var(--main-background-color);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  color: var(--main-color);
  cursor: pointer;
}
/* .card-quotation:hover{
  background-color: antiquewhite;
  color: #333;
} */
.alldata h2{
  text-align: center;
}
.alldata-quotation-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:20px;
  margin:20px;
  
}

/* this is for quotation for button on quotation and yourdata */
.quotation .alldata-quotation-button button {
  background-color: var(--background-quotation);
  border-radius: 10%;
}
.quotation .alldata-quotation-button button:hover{
  background-color: antiquewhite;
  color:#111
}
.yourjobs .alldata-quotation-button button {
  background-color: var(--background-yourjobs);
  border-radius: 10%;
}
.yourjobs .alldata-quotation-button button:hover{
  background-color: antiquewhite;
  color:#111
}
.quotation .card-container-quotation .card-quotation{
  background-color:var(--background-quotation);
}
.yourjobs .card-container-quotation .card-quotation{
  background-color:var(--background-yourjobs);
}
.quotation .card-container-quotation .card-quotation:hover{
  background-color: antiquewhite;
  color: #333;
}
.yourjobs .card-container-quotation .card-quotation:hover{
  background-color: antiquewhite;
  color: #333;
}

/* end of the quotation for button on quotation and yourdata */
/* userdata */
.userdatashow{
  /* background-color: #ccc; */
  background-color: var(--background-quotation);
  position: fixed;
  width: 90vw;
  height: 95%;
top:10px;
  overflow: scroll;
  box-sizing: border-box;
  border: 2px solid #4a4a4a;
  border-radius: 10px;
  padding: 20px;
  transition: background-color 0.3s;
  z-index: 20;
}
.userdatashowyourjobs{
  background-color: var(--background-yourjobs);
}
.userdatashow p{
  border-bottom: 1px solid #111;
}
.customer-details,.price-details,.trip-details{
  
  margin:10px;
  border: 1px solid black;
  
}
.customer-details h3,.price-details h3,.trip-details h3{
  text-align: center;
}
.customer-details p,.price-details p,.trip-details p{
  display: flex;
  justify-content: space-between;
  margin:10px;
}
.viastop-details{
  border:1px solid red;
}
@media (max-width:430px) {

  .login-container{
    width: 80%;
  }
}
/* Styles for FAQ container */
.faq-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd; 
}

/* Styles for FAQ header */
.faq-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

/* Styles for individual FAQ items */
.faq {
  margin-bottom: 20px;
}

/* Styles for FAQ question */
.question {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  cursor: pointer;
}

/* Styles for FAQ answer */
.answer {
  font-size: 16px;
  margin-bottom: 10px;
  display: none;
}
.answer.true{
  display: block;
}
.question-icon{
color:#3498db
}
.answer-icon{
color:#27ae60
}
/* styles.css */

/* Default styles for larger screens */
.navbar {
  background-color:lightgray;
  padding: 0 10px ;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-left img{
height: 15vh;
}
.nav-link {
  margin-right: 15px;
  text-decoration: none;
  color: #fff;
}

.nav-link:hover {
  background-color: #777;

}
nav a{
  text-decoration: none;
  margin-right: 5vw;
  font-size: 1.3em;
  transition: background-color 0.3s ease;
}
nav a.active{
  text-decoration: underline;
  background-color: #111;
}
.toggle-btn {
  cursor: pointer;
  font-size: 40px;
  display: none; 
}

/* Styles for the mobile menu */
.navbar-toggle {
  display: none; 
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .toggle-btn{
    display: block;
  }
  .navbar {
    flex-direction: column;
    align-items: stretch; 
  }
  .navbar-left{
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  img {
    width: 100%; 
    max-width: 200px; 
    /* margin-bottom: 10px; */
  }

  .nav-link {
    margin: 2px 0;
    padding:1vh 0;
  }
  nav {
    display: flex;
    flex-direction: column;
  }
  nav a.active{
    background-color: blue;
  }
.navbar .close{
  display: none;
}
}

/* .contact-us{
margin:20px auto;
width: 80%;
border: 1px solid #ccc;
text-align: center;

} */
.contact-us{
  display: flex;
  flex-direction: row;
  gap:2px;
  justify-content: space-evenly;
  margin: 10px ;

}
.contact-left{
  width: 40%;
  border: 2px solid #ccc;
 
}
.contact-right{
  width: 40%;
  border: 2px solid #ccc;

}
.contact-right form{
  display: grid;
  grid-template-columns: repeat(1fr);
  /* grid-gap:15px; */
  /* flex-direction: column; */

}
.contact-right label {
  display: block;
  margin-bottom: 5px;
}
.contact-right  input,
.contact-right textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.contact-right button:hover{
background-color:white;
color:black;
}
.contact-form-error{
  position: relative;
  
  color: red;
}

@media  (max-width:1000px) {
 .contact-us{
  display: grid;
  grid-template-columns: 1fr;
  
 }
 .contact-left,.contact-right{
  width: 90vw;
  margin:0 auto;
  margin-top: 20px;

 }
}
.footer{
  
  bottom:20;
  max-width: 100%;
  /* height: 20vh; */
  margin-top: auto;
  background-color: #ccc;
  display: grid;
  grid-template-columns: 20% 45% 30%;
  text-align: center;
  justify-content: space-between;
  /* padding-bottom: 15vh; */
  font-size: 0.7em;
}
.contact{
  text-align: left;
  /* padding-left: 1vw; */
}
.copyright{
  /* display: flex;
  justify-content: center; */
  align-items: center;
}
.footer a{
  text-decoration:none;
  color:#111;
}
.footer a:hover{
  color: var(--highlight-color);
  
  
}
.footer p{
  color: #111;
}
.love{
  color:#FF6764 ;
}
.DayNight{
  font-size: 4em;


  }
@media (max-width:390px) {
 .footer{ font-size: 0.3em;}
}

/* Floating Book */

.floatingbook::before {
  content: '🎉 Surprise! Booking Summary';
  display: block;
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #fff;
}
.floatingbook {
  background-color: rgba(163, 163, 223, 0.9);
  position: fixed;
  width: 90vw;
  height: 90%;
  top: 2vh;
  overflow: scroll;
  box-sizing: border-box;
  border: 2px solid #4a4a4a;
  border-radius: 10px;
  padding: 20px;
  transition: background-color 0.3s;
  z-index: 20;
}


.floatingbook:hover {
  background-color: rgb(133, 133, 193);
}

.floatingbook header {
  display: flex;
  justify-content: space-between;
  margin: 1vh 1vw;
  
}
.sub-floatingabout{
  padding: 10px 20px;
}
.floatingbook>header>button{
  display: relative;
  top:0;
}

.floattime {
  text-decoration: underline;
  font-size: 1.2em;
}
.floating-middle-book{
  display: flex;
  flex-direction: row;
  gap:10px;
}
.floatingcontent,
.floatingtotal
{
border:1px solid #111;
width: 45%;
padding:10px;
}
.floatingpickup,
.floatingviastop,
.floatingdropof,
.floatingworker,
.floatingvansize,
.floatinghourNeed,
.floatingviastopmore,
.floatingdescription
{
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  border:1px solid #111;

}
.info-label,
.info-value {
  display: block;
}

.info-label {
  font-weight: bold;
}

.floatingshowmore {
  display: flex;
  justify-content: flex-end;
  margin-right: 5vh;
  cursor: pointer;
}

.floatingshowmore:hover {
  font-weight: bold;
}



.floatingviastopmore,
.floatingdescription {
  margin-bottom: 2vh;
  
}
.floatingtotal {
  /* font-size: 1.6em; */
  text-align: center;
}

.floatingtotal .total-price {
  font-weight: bold;
}

.floatbutton {
  text-align: center;
  margin: 2vh 0;
}

.floatbutton  {
display: flex;
justify-content: center;
gap:20px;
  
}
.floatbutton button:hover{
  background-color: #fff;
  color:#111;
  transform: scale(1.1);
}
.float-after-term {
  margin:10px 0;
  border-top: 1px solid #111;
 }
 .float-after-term p{
  text-align: left;
 }
 .floatingbook-radio-button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:3px;
  margin:2vh 0;
}
@media (max-width:700px){
  .floatingbook header{
    margin: 0;
  }
  .floating-middle-book{
    flex-direction: column;
  }
  .floatingcontent,.floatingtotal{
    width: 100%;
  }


}
@media (max-width:339px) {
  .floatbutton {
/* display: flex; */
flex-direction: column;
align-items: center;
/* gap:20px; */
  }  
  .floatbutton button{
    width: 60%;
   border-radius: 15%;
  }
}
@media (max-width:300px) {
  /* .floatingbook-radio-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap:3px;
  } */
}
/* this is end of floating */
/* @media (max-width:1000px){
  .floatingbook{
    font-size: 0.7em;
  }
  .floatingcontent{
    margin-left: 0;
  }

@media (max-width:600px) {
  .floatingbook{
    height: 90%;
    top:5vh;
  }
  .floatingcontent{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    border:1px solid black;
    gap:0;
    
  
  }
}} */
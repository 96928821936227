/*Home welcome section */
/* Start of mapcontainer */
.mapcontainer {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100vw;
  height: 100vh; */
 
background-image: url("../../assets/Photos/main.png");
/* background-size: 100% 100%; */
/* background-position: center; */
background-size: cover;
background-position: center;
text-align: center;
  overflow: auto;
  background-color:var(--main-background-color);
}


/* Style the form */
.quotesearch {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 2em;
  border-radius: 5px;
  width: 80%;
  
}
.home-warning {
  color:yellow;
  margin-top: 10px;
  /* font-weight: bold; */
}
form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap; 
}

.forminput {
  padding: 0.5em;
  width: 30vw;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
/* background-color: red; */
font-size: 1.2em;
}
.home-input-error {
  color: darkred;
  font-weight: bold;
  width: 70%;
  background-color: #fff3cd; 
  margin: 0 auto;
  padding: 8px; 
  border: 1px solid #ffeeba; 
  border-radius: 4px;
}

.inputbetween {
  font-size: 1em;
  margin: 2vh 10px;
  color: gold; 
}
.mapcontainer button{
  padding: 10px 20px;
  background-color: #007bff;
  color: var(--main-background-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.mapcontainer button:hover{
  transform: scale(1.1);
}

.mainimageheader {
max-width: 90vw;
  /* font-size:1em; */
  width: 80%;
color:var(--highlight-color);
font-weight: bold;
padding:2vh 0;

}
.secondimageheader {
  color:var(----highlight-color);
  font-weight: bold;
  font-size: 1.1em;
}
/* end of mapcontainer */
/* start card payment accept icon */
/* PaymentIcons.css */
.home-payment{
  display: flex;
  flex-direction: column;
  width: 80vw;
  min-width: 50vw;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
}
.payment-header p{
  text-align: center;
}
.payment-icons {
  display: flex;
  gap: 1em;
  /* width: 80vw; */
  justify-content: center;
}

.paypal-icon {
  color: #003087;
}

.amex-icon {
  color: #0076A8;
}

.apple-pay-icon {
  color: #000000;
}

.google-pay-icon {
  color: #4285F4;
}

.mastercard-icon {
  color: #FF5F00;
}
.paypal-icon,
.amex-icon,
.apple-pay-icon,
.google-pay-icon,
.mastercard-icon {
  font-size: 2em; 
}

/* end card payment acceptt icon */
.review-container{
  text-align: center;
  background-color:var(--main-background-color);
  height: auto;
  cursor: pointer;
  margin-bottom: 2vh;

}
.review-container a{
  text-decoration: none;
  color:#444;
}
.review-container button{
  margin-bottom: 2vh;
  background-color: #007bff;
}
.review-container button:hover{
  transform: scale(1.1);
}
.review-cards{
  width:90vw;
  height: auto;
  margin:0 auto;
}
.review-card{

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  border-radius: 3%;
  width: 100%;


}
.review-card p{
  width:50vw;
  /* line-height: 4vh; */
  font-size: 0.5em;
}
.review-card h3{
  padding-top: 1vh;
}
.review-card img{
  max-width: 20px;
}

/* how it work */

  .howitwork {
    text-align: center;
    padding: 20px;
  }
  
  /* Style the title */
  .howitwork h1 {
    color: var(--main-color);
  }
  
  /* Style the cards container */
  .howitworkcards {
    /* display: flex;
    justify-content: space-around;
    margin-top: 20px; */
    width: 50vw;
    margin:0 auto;
  }
  
  /* Style each individual card */
  .howitworkcard {
    width: 90%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Style the icons */
  .howitwork-icon {
    color: rgb(136, 136, 188);
    font-size: 4em;
  }
  
  /* Style the description */
  .howitworkdescription {
    margin-top: 15px;
  }
  /* start about us at homepage */
  .home-about{
    width: 90vw;
    margin: 0 auto;
  }
  .home-about h1{
    text-align: center;
  }
   .about-content{
    width: 80vw;
    margin :0 auto;
  }
  /* end about us at homepage */
  @media (max-width: 900px) {
 
    .quotesearch{
      width: 90vw;
      /* height: 100vh; */
      /* height: auto; */
      /* position: relative; */
    }
    .home-form{
     flex-direction: column;
    align-items: center;
    }
     .forminput{
     
      width: 80vw;
    }
 
  


    .howitworkcards {
      flex-direction: column;
    }
  
    .howitworkcard {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media (max-width:600px) {

  }
  @media (max-width:375px) {
    
  }